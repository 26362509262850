import "core-js/modules/es.array.push.js";
import { showToast } from 'vant';
import 'vant/es/toast/style';
export default {
  data() {
    return {
      visible: true,
      goods: {
        title: '美国伽力果（约680g/3个）',
        price: 2680,
        express: '免运费',
        remain: 19,
        thumb: [{
          image: 'http://img95.699pic.com/photo/40083/1074.jpg_wh860.jpg',
          url: "https://zhuanlan.zhihu.com/p/363513881"
        }, {
          image: 'http://seopic.699pic.com/photo/50044/8443.jpg_wh1200.jpg',
          url: "https://zhuanlan.zhihu.com/p/363513881"
        }, {
          image: 'http://img95.699pic.com/photo/40143/7836.jpg_wh860.jpg',
          url: "https://zhuanlan.zhihu.com/p/363513881"
        }, {
          image: 'http://img95.699pic.com/photo/50046/7545.jpg_wh860.jpg',
          url: "https://zhuanlan.zhihu.com/p/363513881"
        }]
      }
    };
  },
  methods: {
    formatPrice() {
      return '¥' + (this.goods.price / 100).toFixed(2);
    },
    onClickCart() {
      this.$router.push('cart');
    },
    sorry() {
      showToast('暂无后续逻辑~');
    }
  }
};